import {takeLatest, put, select} from "redux-saga/effects"
import {sellerSlice} from './sellerSlice'
import {network} from '../../utils/network'
import {documentTempToID} from '../bid/bidSagas'
import {appSlice} from '../app/appSlice'

function* fetch({payload}) {
  yield put(sellerSlice.actions.setLoading({type: 'selectedSeller', loading: true}))
  const {id} = payload

  try {
    const sellerResponse = yield network.axios({
      method: 'GEt',
      url: `/seller/${id}/`,
    })
    const seller = sellerResponse.data

    yield put(sellerSlice.actions.setSelectedSeller(seller))
  } catch (e) {
    console.log('e', e)
  }
  yield put(sellerSlice.actions.setLoading({type: 'selectedSeller', loading: false}))
}

function* create({payload}) {

}

function* update({payload}) {
  yield put(sellerSlice.actions.setLoading({type: 'seller', loading: true}))
  const {sellerData} = payload

  const store = yield select(store => store)

  const endpointId = store.endpoint.selectedEndpoint.id
  const permission = store.user.permissions.endpoints[endpointId]

  try {
    const method = sellerData.id ? 'PUT' : 'POST'
    const url = sellerData.id ? '/seller/' + sellerData.id + "/" : "/seller/"

    const sellerResponse = yield network.axios({
      method: method,
      url: url,
      data: {
        ...sellerData,
        gender: undefined,
        inn: undefined,
        place_of_birth: undefined,
        merchant_id: permission.merchant_id,
      }
    })
    const seller = sellerResponse.data

    yield put(sellerSlice.actions.setSeller(seller))
  } catch (e) {
    console.log('e', e)
    yield put(appSlice.actions.addMessage({
      type: 'error',
      text: 'Что-то пошло не так',
    }))
  }
  yield put(sellerSlice.actions.setLoading({type: 'seller', loading: false}))
}

function* documentRecognise({payload, type}) {
  yield put(sellerSlice.actions.setLoading({type: 'documentRecognized', loading: true}))
  const {file} = payload
  const id = documentTempToID(file.tempId)
  try {
    const response = yield network.axios({
      method: 'GET',
      url: `/bid-file-document/${id}/recognise/`,
    })
    yield put(sellerSlice.actions.setLoading({type: 'documentRecognized', loading: false, data: response.data}))
  } catch (e) {
    yield put(appSlice.actions.addMessage({
      type: 'error',
      text: 'Распознавание паспорта не удалось',
    }))
    yield put(sellerSlice.actions.setLoading({type: 'documentRecognized', loading: false}))
  }
}

export function* sellerSagas() {
  yield takeLatest(sellerSlice.actions.create.type, create)
  yield takeLatest(sellerSlice.actions.update.type, update)
  yield takeLatest(sellerSlice.actions.fetch.type, fetch)

  yield takeLatest(sellerSlice.actions.documentRecognise.type, documentRecognise)
}
