import {m} from 'framer-motion';
// @mui
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
// routes
import {useRouter} from 'src/routes/hooks';
// auth
import {useAuthContext} from 'src/auth/hooks';
// components
import {varHover} from 'src/components/animate';
import CustomPopover, {usePopover} from 'src/components/custom-popover';
import {useSelector} from 'react-redux'
import UserFillIcon from 'src/assets/icons/user-fill-icon'
import LogoutIcon from '@mui/icons-material/Logout';
import SettingsIcon from '@mui/icons-material/Settings';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import {Stack} from '@mui/material';


export default function AccountPopover(props) {

  const {smDown} = props

  const router = useRouter();

  const userName = useSelector(store => store.user?.permissions?.user_data?.name)

  const {logout} = useAuthContext();

  const popover = usePopover();

  const handleLogout = async () => {
    try {
      await logout();
      popover.onClose();
      router.replace('/');
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <IconButton
        component={m.button}
        whileTap="tap"
        whileHover="hover"
        variants={varHover(1.05)}
        onClick={popover.onOpen}
        sx={{
          width: 44,
          height: 44,
          borderRadius: 1,
          background: "#F4F6F8",
        }}
      >
        <UserFillIcon />
      </IconButton>

      <CustomPopover open={popover.open} onClose={popover.onClose} sx={{width: 200, p: 0, mt: smDown ? 2.5 : 1.5}}>
        {userName && userName.length && (
          <Stack>
            <Stack sx={{p: 2, pb: 1.5}} direction='row' alignItems='center' gap="12px">
              <Stack sx={{
                width: '32px',
                height: '32px',
                backgroundColor: '#F4F6F8',
                borderRadius: '8px'
              }}
                alignItems='center'
                justifyContent='center'
              >
                <UserFillIcon sx={{width: '16px', height: '16px'}} />
              </Stack>
              <Box>
                <Typography variant="subtitle2" color='text.primary' noWrap>
                  {userName}
                </Typography>

                <Typography variant="body2" color='text.tertiary' fontSize={12} noWrap>
                  Пользователь
                </Typography>
              </Box>
            </Stack>

            <Box px={2}>
              <Divider />
            </Box>
          </Stack>
        )}
{/* 
        <MenuItem
          onClick={() => {}}
          sx={{
            m: 1,
            [`& svg.MuiSvgIcon-root`]: {
              marginRight: '12px'
            }
          }}
        >
          <AccountBoxIcon sx={{width: 20, height: 20, fill: '#637381'}} />
          Профиль
        </MenuItem>

        <MenuItem
          onClick={() => {}}
          sx={{
            m: 1, [`& svg.MuiSvgIcon-root`]: {
              marginRight: '12px'
            }
          }}
        >
          <SettingsIcon sx={{width: 20, height: 20, fill: '#637381'}} />
          Настройки
        </MenuItem> */}

        <MenuItem
          onClick={handleLogout}
          sx={{
            m: 1, color: 'error.main', [`& svg.MuiSvgIcon-root`]: {
              marginRight: '12px'
            }
          }}
        >
          <LogoutIcon sx={{width: 20, height: 20, fill: 'error.main'}} />
          Выйти
        </MenuItem>
      </CustomPopover >
    </>
  );
}
