import { Suspense, useEffect } from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter,useLocation, useNavigationType, createRoutesFromChildren, matchRoutes } from 'react-router-dom'
import { HelmetProvider } from 'react-helmet-async'
import "yet-another-react-lightbox/styles.css"
import "./theme/style.css"

import App from './App'
import * as Sentry from '@sentry/react'
import { appConfig } from './config/appConfig'

if (appConfig.environment.REACT_APP_SENTRY_DSB) {
  Sentry.init({
    release: appConfig.environment.REACT_APP_VERSION,
    dsn: appConfig.environment.REACT_APP_SENTRY_DSB,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
    ],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    tracesSampleRate: 1.0,
    tracePropagationTargets: ["app.mozenscrap.ru","full.mozenscrap.ru", "light.mozenscrap.ru", "app.mozenauto.ru"],
    integrations: [],
  })

  import("@sentry/browser").then((lazyLoadedSentry) => {
    Sentry.addIntegration(lazyLoadedSentry.replayIntegration({maskAllText: true}));
  });
}

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <HelmetProvider>
    <BrowserRouter>
      <Suspense>
        <App />
      </Suspense>
    </BrowserRouter>
  </HelmetProvider>
)
