import createSagaMiddleware from 'redux-saga'
import {configureStore} from "@reduxjs/toolkit"
import {rootReducer} from './rootReducer'
import rootSaga from './rootSaga'
import {api} from './api'

const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => [
    ...getDefaultMiddleware(),
    api.middleware,
    sagaMiddleware,
  ]
});

sagaMiddleware.run(rootSaga)

const {dispatch} = store;

export {store, dispatch}
