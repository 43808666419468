import Box from '@mui/material/Box'
import React, {useEffect, useState} from 'react'
import {Snackbar} from '@mui/material'
import Stack from '@mui/material/Stack'
import Alert from '@mui/material/Alert'
import {useSelector} from 'react-redux'
import {dispatch} from '../store/store'
import {appSlice} from '../store/app/appSlice'

// <Alert severity="error">This is an error message!</Alert>
// <Alert severity="warning">This is a warning message!</Alert>
// <Alert severity="info">This is an information message!</Alert>
// <Alert severity="success">This is a success message!</Alert>

// dispatch(appSlice.actions.addMessage({
//   type: 'info',
//   text: 'hell',
// }))

export default function InfoMessages() {
  const messages = useSelector(store => store.app.messages)
  const [autoClosed, setAutoClosed] = useState([])

  // useEffect(() => {
  //   dispatch(appSlice.actions.addMessage({
  //     type: 'error',
  //     text: 'Распознавание паспорта не удалось',
  //   }))
  // }, [])

  const handleClose = (message) => {
    dispatch(appSlice.actions.removeMessage(message))
  }

  useEffect(() => {
    messages.forEach(message => {
      if(message.autoClose && !autoClosed.includes(message.key)) {
        setAutoClosed([...autoClosed, message.key])
        setTimeout(() => dispatch(appSlice.actions.removeMessage(message)), 5e3)
      }
    })
  }, [messages, autoClosed])

  return (
    <Snackbar
      open={messages.length > 0}
      onClose={handleClose}
      anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
    >
      <Box sx={{width: '100%'}}>
        <Stack spacing={2} direction="column" sx={{width: '100%'}}>
          {messages.map(message => {
            return (
              <Alert
                key={message.key}
                onClose={() => handleClose(message)}
                severity={message.type}
                sx={{width: '100%'}}
              >
                {message.text}
              </Alert>
            )
          })}
        </Stack>
      </Box>
    </Snackbar>
  )
}

export {InfoMessages}
