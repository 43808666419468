import PropTypes from 'prop-types'; // @mui
import {alpha} from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
// hooks
import {useResponsive} from 'src/hooks/use-responsive';
// components
import Iconify from 'src/components/iconify';
import {useSettingsContext} from 'src/components/settings';
// ----------------------------------------------------------------------

export default function NavToggleButton({sx, ...other}) {

  const settings = useSettingsContext();

  const lgUp = useResponsive('up', 'lg');

  if (!lgUp) {
    return null;
  }

  return (
    <IconButton
      size="small"
      onClick={() =>
        settings.onUpdate('themeLayout', settings.themeLayout === 'vertical' ? 'mini' : 'vertical')
      }
      sx={{
        backgroundColor: "#E8ECF1",
        height: 34,
        width: 34,
        borderRadius: "8px",
        '&:hover': {
          backgroundColor: (theme) =>
            alpha(theme.palette.grey[500], 0.24),
        },
        ...sx,
      }}
      {...other}
    >
      <Iconify
        width={16}
        icon={
          settings.themeLayout === 'vertical'
            ? 'eva:arrow-ios-back-fill'
            : 'eva:arrow-ios-forward-fill'
        }
      />
    </IconButton>
  );
}

NavToggleButton.propTypes = {
  sx: PropTypes.object,
};
