
import { memo } from 'react';
import Box from '@mui/material/Box';

function MenuIcon({ ...other }) {
  return (
    <Box
      component="svg"
      width='20px'
      height='20px'
      fill="none"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
      {...other}
    >
      <path d="M10.8333 14.1667C11.2936 14.1667 11.6667 14.5398 11.6667 15C11.6667 15.4602 11.2936 15.8333 10.8333 15.8333H3.33333C2.8731 15.8333 2.5 15.4602 2.5 15C2.5 14.5398 2.8731 14.1667 3.33333 14.1667H10.8333ZM16.6667 9.16667C17.1269 9.16667 17.5 9.53976 17.5 10C17.5 10.4602 17.1269 10.8333 16.6667 10.8333H3.33333C2.8731 10.8333 2.5 10.4602 2.5 10C2.5 9.53976 2.8731 9.16667 3.33333 9.16667H16.6667ZM16.6667 4.16667C17.1269 4.16667 17.5 4.53976 17.5 5C17.5 5.46024 17.1269 5.83333 16.6667 5.83333H3.33333C2.8731 5.83333 2.5 5.46024 2.5 5C2.5 4.53976 2.8731 4.16667 3.33333 4.16667H16.6667Z" fill="#637381" />
    </Box>
  );
}

export default memo(MenuIcon);