import {createApi} from '@reduxjs/toolkit/query/react'
import {network} from '../utils/network'
import {appSlice} from './app/appSlice'
import {store} from './store'

const axiosBaseQuery = async ({url, method, data, params, ...rest}) => {
  try {
    const result = await network.axios({
      url: (process.env.REACT_APP_API_URI ? process.env.REACT_APP_API_URI : "/") + url,
      method,
      data,
      params,
      ...rest
    })
    return {data: result.data}
  } catch (axiosError) {
    let err = axiosError

    if (err?.response?.data?.message) {
      store.dispatch(appSlice.actions.addMessage({
        type: 'error',
        text: err?.response?.data?.message || err?.response?.data?.detail,
      }))
    }

    return {
      error: {
        status: err.response?.status,
        data: err.response?.data || err.message,
      },
    }
  }
}

export const api = createApi({
  reducerPath: 'splitApi',
  baseQuery: axiosBaseQuery,
  tagTypes: [],
  endpoints: () => ({}),
})
