import PropTypes from 'prop-types';
import {useFormContext, Controller} from 'react-hook-form';
// @mui
import TextField from '@mui/material/TextField'
// ----------------------------------------------------------------------

export default function RHFTextField({name, helperText, type, inputMode, InputProps, inputProps, ...other}) {
  const {control} = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({field, fieldState: {error}}) => (
        <TextField
          {...field}
          fullWidth
          type={type === 'number' ? undefined : type}
          value={type === 'number' && field.value === 0 ? '0' : field.value || ''}
          onChange={(event) => {
            if (type === 'number' && event.target.value) {
              const val = event.target.value ? event.target.value.replace(',', '.') : ''
              const num = Number(val)
              if (`${num}.` === val) {
                return field.onChange(val)
              }
              if (!Number.isNaN(num)) {
                return field.onChange(val)
              }
            } else {
              field.onChange(event.target.value);
            }
          }}
          error={!!error}
          helperText={error ? error?.message : helperText}
          {...other}
          inputProps={{
            ...inputProps,
            inputMode: inputMode,
          }}
          InputProps={InputProps}
        />
      )}
    />
  );
}

RHFTextField.propTypes = {
  helperText: PropTypes.object,
  name: PropTypes.string,
  type: PropTypes.string,
};
