export const bidNav = ({pathname, registryId, id}) => {
  const pathPage = pathname?.split('/')[1]
  const isRegistryPage = pathPage === 'registers'
  const isOperationsPage = pathPage === 'operations'

  const listLink =
    isOperationsPage ? `/operations`
      : (isRegistryPage && registryId) ? `/registers/${registryId}`
        : '/'

  const viewLink =
    (isOperationsPage && id) ? `/operations/view/${id}`
      : (isRegistryPage && registryId && id) ? `/registers/${registryId}/view/${id}`
        : '/'

  return {
    pathPage,
    isRegistryPage,
    isOperationsPage,
    listLink,
    viewLink
  }
}
