// @mui
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
// utils
import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import CustomPopover, {usePopover} from 'src/components/custom-popover';
import {useSelector} from 'react-redux'
import Button from '@mui/material/Button'
import {dispatch} from '../../store/store'
import {endpointSlice} from '../../store/endpoint/endpoingSlice'
import {useNavigate} from 'react-router-dom'
import {Stack} from '@mui/material';
import {useResponsive} from 'src/hooks/use-responsive';
// ----------------------------------------------------------------------

export default function ContactsPopover() {
  const popover = usePopover();
  const {endpoints, selectedEndpoint} = useSelector(store => store.endpoint)
  const navigate = useNavigate()
  const smDown = useResponsive('down', 'sm')
  const selectEndpoint = (endpoint) => {
    dispatch(endpointSlice.actions.selectEndpoint(endpoint))
    popover.onClose()
    navigate('/operations')
  }

  return (
    <>
      {/*{selectedEndpoint && (*/}
      {/*  <Box>*/}
      {/*    <ListItemText*/}
      {/*      primary={selectedEndpoint.name}*/}
      {/*      secondary={selectedEndpoint.short_name}*/}
      {/*      primaryTypographyProps={{typography: 'subtitle2'}}*/}
      {/*      secondaryTypographyProps={{*/}
      {/*        typography: 'caption',*/}
      {/*        color: 'text.disabled',*/}
      {/*      }}*/}
      {/*    />*/}
      {/*  </Box>*/}
      {/*)}*/}

      <Button
        variant="link"
        onClick={popover.onOpen}
        sx={{
          mr: 'auto',
          ...(popover.open && {
            bgcolor: (theme) => theme.palette.action.selected,
          }),
        }}
      >
        <Stack sx={{textAlign: 'left'}} direction='row' alignItems="center">
          <ListItemText
            primary={selectedEndpoint.name}
            primaryTypographyProps={{typography: 'subtitle2'}}
            secondaryTypographyProps={{
              typography: 'caption',
              color: 'text.disabled',
            }}
          />
          <Iconify sx={{marginLeft: '8px', width: '18px', Height: '18px'}} icon="iconamoon:arrow-down-2-duotone" />
        </Stack>
      </Button>


      <CustomPopover arrow='top-left' open={popover.open} onClose={popover.onClose} sx={{width: 320, overflow: "auto", p: 0, mt: smDown ? 2.5 : 1.5}}>

        <Scrollbar>
          {endpoints.data.map((endpoint) => (
            <MenuItem key={endpoint.id} sx={{p: 1}}>

              <ListItemText
                primary={endpoint.name}
                secondary={endpoint.short_name}
                primaryTypographyProps={{typography: 'subtitle2'}}
                secondaryTypographyProps={{
                  typography: 'caption',
                  color: 'text.disabled',
                }}
                onClick={() => selectEndpoint(endpoint)}
              />
            </MenuItem>
          ))}
        </Scrollbar>
      </CustomPopover>
    </>
  );
}
