import PropTypes from 'prop-types';
import {useFormContext, Controller} from 'react-hook-form';
// @mui
import {MobileDatePicker} from '@mui/x-date-pickers/MobileDatePicker'
import { DateField } from "@mui/x-date-pickers/DateField";

// ----------------------------------------------------------------------

export default function RHFMobileDatePicker({name, helperText, type, variant, size, ...other}) {
  const {control} = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({field, fieldState: {error}}) => (
        <DateField
          {...field}
          variant={variant}
          size={size}
          fullWidth
          value={field.value}
          onChange={(value, z) => {
            field.onChange(value)
          }}
          error={!!error}
          helperText={error ? error?.message : helperText}
          {...other}
        />
      )}
    />
  );
}
