// scrollbar
import 'simplebar-react/dist/simplebar.min.css';

// image
import 'react-lazy-load-image-component/src/effects/blur.css'

// ----------------------------------------------------------------------
import {Provider} from 'react-redux'
import {dispatch, store} from './store/store'
// routes
import Router from 'src/routes/sections'
// theme
import ThemeProvider from 'src/theme'
// hooks
import {useScrollToTop} from 'src/hooks/use-scroll-to-top'
// components
import ProgressBar from 'src/components/progress-bar'
import {MotionLazy} from 'src/components/animate/motion-lazy'
import {SettingsProvider, SettingsDrawer} from 'src/components/settings'
// auth
import {AuthProvider, AuthConsumer} from 'src/auth/context/jwt'
import {useNavigate} from 'react-router-dom'
import {useEffect} from 'react'
import {appSlice} from './store/app/appSlice'
//
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider'
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import ru from 'date-fns/locale/ru'
import InfoMessages from './utils/InfoMessages'
import {network} from './utils/network'

import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file

import './externals/posthog'
import {useResponsive} from './hooks/use-responsive';
import UserDataModal from './components/user-data-modal/user-data-modal';

// ----------------------------------------------------------------------

console.log(process.env.REACT_APP_VERSION)

export default function App() {
  const smDown = useResponsive("down", "sm")
  useScrollToTop(smDown);
  const navigate = useNavigate();

  useEffect(() => {
    network.navigate = navigate
    dispatch(appSlice.actions.restore())
  }, [])

  return (
    <Provider store={store}>
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        adapterLocale={ru}
      >
        <AuthProvider>
          <SettingsProvider
            defaultSettings={{
              themeMode: 'light', // 'light' | 'dark'
              themeDirection: 'ltr', //  'rtl' | 'ltr'
              themeContrast: 'default', // 'default' | 'bold'
              themeLayout: 'vertical', // 'vertical' | 'horizontal' | 'mini'
              themeColorPresets: 'default', // 'default' | 'cyan' | 'purple' | 'blue' | 'orange' | 'red'
              themeStretch: false,
            }}
          >
            <ThemeProvider>
              <MotionLazy>
                <SettingsDrawer />
                <ProgressBar />
                <AuthConsumer>
                  <UserDataModal />
                  <InfoMessages />
                  <Router />
                </AuthConsumer>
              </MotionLazy>
            </ThemeProvider>
          </SettingsProvider>
        </AuthProvider>
      </LocalizationProvider>
    </Provider>
  );
}
