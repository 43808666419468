import {createSlice} from "@reduxjs/toolkit"

const initialState = {
  car: {
    loading: false,
    data: null,
  }
}

const carSlice = createSlice({
  name: 'car',
  initialState,
  reducers: {
    create(state, action) {

    },
    update(state, action) {

    },
    setCar(state, action) {
      const {payload} = action
      state.car.data = payload
    },
    setLoading(state, action) {
      const {payload} = action
      state[payload.type].loading = payload.loading
    },
  },
})

const carReducer = carSlice.reducer

export {carSlice, carReducer}
