
import { memo } from 'react';
import Box from '@mui/material/Box';

function UserFillIcon({ ...other }) {
  return (
    <Box
      component="svg"
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
      {...other}
    >
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M13.3333 6.66665C13.3333 4.82498 11.8416 3.33331 9.99992 3.33331C8.15825 3.33331 6.66659 4.82498 6.66659 6.66665C6.66659 8.50831 8.15825 9.99998 9.99992 9.99998C11.8416 9.99998 13.3333 8.50831 13.3333 6.66665ZM3.33325 15V15.8291C3.33325 16.2917 3.70821 16.6666 4.17075 16.6666H15.8291C16.2916 16.6666 16.6666 16.2917 16.6666 15.8291V15C16.6666 12.0833 12.2249 11.25 9.99992 11.25C7.77492 11.25 3.33325 12.0833 3.33325 15Z" fill="#212B36" />
      </svg>
    </Box>
  );
}

export default memo(UserFillIcon);