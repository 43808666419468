import {lazy, Suspense} from 'react';
import {Outlet} from 'react-router-dom';
// auth
import {AuthGuard} from 'src/auth/guard';
// layouts
import DashboardLayout from 'src/layouts/dashboard';
// components
import {LoadingScreen} from 'src/components/loading-screen'

const RegistersPage = lazy(() => import('src/pages/registers/RegistersPage'));
const RegistryPage = lazy(() => import('src/pages/registry/RegistryPage'));

export const registersRoutes = [
  {
    path: '/registers',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      {
        index: true,
        element: <RegistersPage />,
      },
      {
        path: ':registryId',
        element: <RegistryPage />,
      },
      {
        path: ':registryId/edit/:id',
        element: <RegistryPage />,
      },
      {
        path: ':registryId/view/:id',
        element: <RegistryPage />,
      },
    ],
  },
];
