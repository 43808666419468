const defaultBidBlocks = {
  type: 'default',
  scrapPhotos: {
    display: true,
    // required: true
  },
  scrap: {
    display: true,
  },
  scrapList: {
    display: true,
  },
  seller: {
    display: true,
    type: 'modal', // modal, inline
    mainPhoto: {display: true},
    passport: true,
    registrationPhoto: {display: true},
  },
  car: {
    display: true,
  },
  withdrawal: {
    display: true,
  },
  offer: {
    display: true,
  },
  payment: {
    display: true,
    canEditAmount: false
  }
}

const shortBidBlocks = {
  type: 'short',
  scrap: {
    display: true,
  },
  scrapPhotos: {
    display: false,
  },
  scrapList: {
    display: false,
  },
  seller: {
    type: 'inline', // modal, inline
    display: true,
    passport: false,
    mainPhoto: {display: true},
    registrationPhoto: {display: false},
  },
  car: {
    display: false,
  },
  withdrawal: {
    display: true,
  },
  offer: {
    display: false,
  },
  payment: {
    display: true,
    canEditAmount: false
  }
}

const lightBidBlocks = {
  type: 'light',
  scrap: {
    display: true,
  },
  scrapPhotos: {
    display: true,
  },
  scrapList: {
    display: true,
  },
  seller: {
    display: false,
    preset: true,
    passport: false,
    type: 'inline', // modal, inline
    mainPhoto: {display: true},
    registrationPhoto: {display: true},
  },
  car: {
    display: true,
  },
  withdrawal: {
    display: true,
  },
  offer: {
    display: true,
  },
  payment: {
    display: true,
    canEditAmount: true
  }
}

const autoBidBlocks = {
  type: 'auto',
  scrap: {
    display: false,
  },
  auto: {
    display: true,
  },
  scrapPhotos: {
    display: true,
  },
  scrapList: {
    display: false,
  },
  seller: {
    display: true,
    preset: true,
    passport: false,
    type: 'inline', // modal, inline
    mainPhoto: {display: false},
    registrationPhoto: {display: false},
  },
  car: {
    display: false,
  },
  withdrawal: {
    display: true,
  },
  offer: {
    display: true,
  },
  payment: {
    display: true,
    canEditAmount: true
  }
}

const presets = {
  default: defaultBidBlocks,
  short: shortBidBlocks,
  light: lightBidBlocks,
  auto: autoBidBlocks,
}

export const bidBlocks = presets[process.env.REACT_APP_HMETAL_FLOW] ? presets[process.env.REACT_APP_HMETAL_FLOW] : presets.default
