import {takeLatest, put} from "redux-saga/effects"
import {carSlice} from './carSlice'
import {network} from '../../utils/network'
import {appSlice} from '../app/appSlice'

function* create({payload}) {
  yield put(carSlice.actions.setLoading({type: 'car', loading: true}))
  try {
    const response = yield network.axios({
      method: 'POST',
      url: '/car/',
      data: {
        ...payload,
      },
    })
    const car = response.data
    yield put(carSlice.actions.setCar(car))
  } catch (e) {

  }
  yield put(carSlice.actions.setLoading({type: 'car', loading: false}))
}

function* update({payload}) {
  yield put(carSlice.actions.setLoading({type: 'car', loading: true}))
  try {
    const response = yield network.axios({
      method: 'PUT',
      url: `/car/${payload.id}/`,
      data: {
        // ...payload,
        "car_model_id": payload.car_model_id,
        "number": payload.number,
        "trailer_number": payload.trailer_number,
      }
    })
    const car = response.data
    yield put(carSlice.actions.setCar(car))
  } catch (e) {
    console.log('e', e)
    yield put(appSlice.actions.addMessage({
      type: 'error',
      text: 'Что-то пошло не так',
    }))
  }
  yield put(carSlice.actions.setLoading({type: 'car', loading: false}))
}

export function* carSagas() {
  yield takeLatest(carSlice.actions.create.type, create)
  yield takeLatest(carSlice.actions.update.type, update)
}
