import {takeLatest, put, take, select} from "redux-saga/effects"
import {appSlice} from './appSlice'
import {userSlice} from '../user/userSlice'

function* restore({payload}) {
  yield put(userSlice.actions.restore())

  while (true) {
    yield take(userSlice.actions.setLoading.type)
    const user = yield select(store => store.user)

    if(!user.loading) {
      break
    }
  }

  yield put(appSlice.actions.setLoading(false))
}

export function* appSagas() {
  yield takeLatest(appSlice.actions.restore.type, restore)
}
