// @mui
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
// theme
import {hideScroll} from 'src/theme/css';
// components
import Logo from 'src/components/logo';
import {NavSectionMini} from 'src/components/nav-section';
//
import {NAV} from '../config-layout';
import {useNavData} from './config-navigation';
import {NavToggleButton} from '../_common';

// ----------------------------------------------------------------------

export default function NavMini() {

  const navData = useNavData();

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: {lg: 0},
        width: {lg: NAV.W_MINI},
      }}
    >

      <Stack
        sx={{
          pb: 2,
          height: 1,
          position: 'fixed',
          width: NAV.W_MINI,
          background: '#fff',
          ...hideScroll.x,
        }}
      >
        <Box sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          background: '#F9FAFB',
          borderBottom: '1px solid #CDD5DC',
          mb: 4
        }}>
          <Logo sx={{my: 2}} />
        </Box>
        <Box
          sx={{
            textAlign: 'center',
            mb: 2
          }}
        >
          <NavToggleButton />
        </Box>

        <NavSectionMini data={navData} config={{hiddenLabel: true}} />
      </Stack>
    </Box>
  );
}
