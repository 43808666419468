import {api} from '../api'

export const userApi = api.injectEndpoints({
  endpoints: (build) => ({
    // login: build.query({
    //   query(params, test) {
    //
    //     console.log('params', params)
    //
    //     return {
    //       url: `/token/`,
    //       method: 'POST',
    //       data: {
    //         username: params.username,
    //         password: params.password,
    //       },
    //     }
    //   },
    // }),
  }),
})
