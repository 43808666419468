import {createSlice} from "@reduxjs/toolkit"

const initialState = {
  selectedSeller: {
    loading: false,
    data: null,
  },
  seller: {
    loading: false,
    data: null,
  },
  documentRecognized: {
    loading: false,
    data: null,
  },
}

const sellerSlice = createSlice({
  name: 'seller',
  initialState,
  reducers: {
    fetch(state, action) {

    },
    create(state, action) {

    },
    update(state, action) {

    },
    setSeller(state, action) {
      const {payload} = action
      state.seller.data = payload
    },
    setSelectedSeller(state, action) {
      const {payload} = action
      state.selectedSeller.data = payload
    },
    setDocumentRecognized(state, action) {
      const {payload} = action
      state.documentRecognized.data = payload
    },
    setLoading(state, action) {
      const {payload} = action
      state[payload.type].loading = payload.loading
      if (payload.data) {
        state[payload.type].data = payload.data
      }
    },
    documentRecognise() {
      //
    },
  },
})

const sellerReducer = sellerSlice.reducer

export {sellerSlice, sellerReducer}
