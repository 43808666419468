import {put, takeLatest} from "redux-saga/effects"
import {network} from '../../utils/network'
import {endpointSlice} from './endpoingSlice'

function* fetch() {
  yield put(endpointSlice.actions.setLoading({type: 'endpoints', loading: true}))
  try {
    const response = yield network.axios({
      method: 'GET',
      url: '/endpoint/',
    })
    yield put(endpointSlice.actions.setEndpoints(response.data))
    const endpointId = localStorage.getItem('endpoint')
    const found = response.data.find(i => i.id === endpointId)

    if(found) {
      yield put(endpointSlice.actions.selectEndpoint(found))
    } else {
      yield put(endpointSlice.actions.selectEndpoint(response.data[0]))
    }
  } catch (e) {
    // todo: error?
  }
  yield put(endpointSlice.actions.setLoading({type: 'endpoints', loading: false}))
}

export function* endpointSagas() {
  yield takeLatest(endpointSlice.actions.fetch.type, fetch)
}
