import {lazy, Suspense} from 'react';
import {Outlet} from 'react-router-dom';
// auth
import {AuthGuard} from 'src/auth/guard';
// layouts
import DashboardLayout from 'src/layouts/dashboard';
// components
import {LoadingScreen} from 'src/components/loading-screen'

const OperationsPage = lazy(() => import('src/pages/operations/OperationsPage'));

export const dashboardRoutes = [
  {
    path: '/operations',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      {
        index: true,
        element: <OperationsPage />,
      },
      {
        path: 'edit/:id',
        element: <OperationsPage />,
      },
      {
        path: 'view/:id',
        element: <OperationsPage />,
      },
    ],
  },
];
