export const appConfig = {}
window.appConfig = appConfig

const envKeys = Object.keys(process.env).filter(key => key.includes('REACT_APP_'))
appConfig.environment = {}
envKeys.forEach(key => {
  if (!process.env[key]) {
    return
  }
  if (process.env[key] === `__${key}__`) {
    return
  }
  appConfig.environment[key] = process.env[key]
})

