import PropTypes from 'prop-types'; // @mui
import Box from '@mui/material/Box';
// hooks
import {useResponsive} from 'src/hooks/use-responsive';
// components
import {useSettingsContext} from 'src/components/settings';
//
import {HEADER, NAV} from '../config-layout';

// ----------------------------------------------------------------------

const SPACING = 18;

export default function Main({children, sx, ...other}) {
  const settings = useSettingsContext();

  const lgUp = useResponsive('up', 'lg');
  const smDown = useResponsive('down', 'sm');
  const smUp = useResponsive('up', 'sm');

  const isNavHorizontal = settings.themeLayout === 'horizontal';

  const isNavMini = settings.themeLayout === 'mini';

  if (isNavHorizontal) {
    return (
      <Box
        component="main"
        sx={{
          background: '#F4F6F8',
          minHeight: 1,
          display: 'flex',
          flexDirection: 'column',
          pt: `${HEADER.H_MOBILE + 24}px`,
          pb: 10,
          ...(lgUp && {
            pt: `${HEADER.H_MOBILE * 2 + 40}px`,
            pb: 15,
          }),
        }}
      >
        {children}
      </Box>
    );
  }

  return (
    <Box
      component="main"
      sx={{
        background: '#F4F6F8',
        flexGrow: 1,
        minHeight: 1,
        display: 'flex',
        flexDirection: 'column',
        width: `calc(100% - ${NAV.W_VERTICAL}px)`,
        ...(isNavMini && {
          width: `calc(100% - ${NAV.W_MINI}px)`,
        }),
        ...(smDown && {
          py: `${HEADER.H_MOBILE + SPACING}px`,

        }),
        ...(smUp && {
          py: `${HEADER.H_DESKTOP}px`,
        })
      }}
    >
      {children}
    </Box>
  );
}

Main.propTypes = {
  children: PropTypes.node,
  sx: PropTypes.object,
};
