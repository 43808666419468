// ----------------------------------------------------------------------

export const HEADER = {
  H_MOBILE: 68,
  H_DESKTOP: 76,
  H_DESKTOP_OFFSET: 76 - 16,
};

export const NAV = {
  W_VERTICAL: 280,
  W_MINI: 88,
};
