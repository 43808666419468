import PropTypes from 'prop-types';
import {useFormContext, Controller} from 'react-hook-form';
// @mui
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

// ----------------------------------------------------------------------

export default function RHFAutocomplete({name, label, placeholder, helperText, variant, inputMode, ...other}) {
  const {control, setValue} = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({field, fieldState: {error}}) => (
        <Autocomplete
          {...field}
          value={field.value === undefined ? null : field.value}
          onChange={(event, newValue) => setValue(name, newValue, {shouldValidate: true})}
          renderInput={(params) => {
            params.inputProps.inputMode = inputMode
            params.inputProps.value = params.inputProps.value || ''
            return (
              <TextField
                variant={variant}
                label={label}
                placeholder={placeholder}
                error={!!error}
                helperText={error ? error?.message : helperText}
                {...params}
              />
            )
          }}
          {...other}
        />
      )}
    />
  );
}

RHFAutocomplete.propTypes = {
  helperText: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
};
