import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import LoadingButton from '@mui/lab/LoadingButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import FormProvider, { RHFTextField } from 'src/components/hook-form';
import { Box, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useEffect, useState } from 'react';
import { useResponsive } from 'src/hooks/use-responsive';
import { useSelector } from 'react-redux';
import { getStorage, setStorage } from 'src/hooks/use-local-storage';
import PincodeLockIcon from 'src/assets/icons/pincode-lock-icon';

export default function UserDataModal() {
  const permissions = useSelector(store => store.user?.permissions)
  const selectedEndpointId = useSelector(store => store.endpoint.selectedEndpoint?.id)
  const endpointPermissions = permissions?.endpoints ? permissions.endpoints[selectedEndpointId] : {}
  const userData = permissions?.user_data

  const userId = permissions?.user_id
  const merchantId = endpointPermissions?.merchant_id

  const [open, setOpen] = useState(false)

  const onClose = () => setOpen(false)
  const onOpen = () => setOpen(true)
  const smDown = useResponsive("down", "sm")

  const schema = Yup.object().shape({
    email: Yup.string().required('Поле обязательно к заполнению.').email('Не валидный eмайл'),
    phone: Yup.string().required('Поле обязательно к заполнению.'),
  });

  const posthog = window.posthog

  const defaultValues = {
    email: '',
    phone: '',
  };

  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  useEffect(() => {
    if (!permissions) {
      return
    }
    if (userData?.phone && userData?.email) {
      return
    }
    const userStorageData = getStorage(`user-${userId}-${merchantId}-${selectedEndpointId}`)
    if (!userStorageData) {
      onOpen()
      return
    }
  }, [permissions, selectedEndpointId, merchantId, userId, userData])


  const onSubmit = handleSubmit(async (data) => {
    const user = `user-${userId}-${merchantId}-${selectedEndpointId}`
    const userData = getStorage(user)
    const newUserData = {
      phone: data?.phone,
      email: data?.email,
      position: permissions?.user_data?.position,
      user_id: permissions?.user_id,
      name: permissions?.user_data?.name,
    }

    console.log('newUserData',newUserData)

    if (!userData) {
      setStorage(user, newUserData)
      if (typeof posthog?.capture === 'function') {
        posthog?.capture('user_data', newUserData)
      }
    }
    onClose()
  });

  return (
    <Dialog
      fullScreen={smDown}
      open={open}
      PaperProps={{
        sx: {
          maxWidth:  smDown ? 480 : 420,
          width: '100%',
        },
      }}
    >
      <FormProvider methods={methods} onSubmit={onSubmit}>
        <DialogTitle>
          <Stack>
            <Box align="center" mt={2} mb={1}>
              <PincodeLockIcon />
            </Box>
            <Typography variant="h2" mb={2} align='center' sx={{ fontWeight: 700 }} >
              Добавьте <br />телефон и email
            </Typography>

            <Typography variant="body2" align='center' color="#454F5B">
              Для усиления безопасности доступа к данным, нам необходимо собрать недостающую информацию.
            </Typography>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <Stack spacing={2.5} mt={1}>
            <Box>
              <RHFTextField
                name="email"
                label="Email*"
              />
              <Typography variant="caption" as='div' mt={1} sx={{ color: 'text.secondary' }}>
                Будет использоваться для добавления в новые компании и смене пароля
              </Typography>
            </Box>
            <Box>
              <RHFTextField
                name="phone"
                label="Телефон*"
              />
              <Typography variant="caption" mb={2.5} as='div' mt={1} sx={{ color: 'text.secondary' }}>
                Будет использоваться для кода подтверждения
                при входе в систему Mozen
              </Typography>

              <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                *  Просьба использовать корпоративные данные
                при их наличии
              </Typography>
            </Box>
          </Stack>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            fullWidth
            color="inherit"
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            Сохранить
          </LoadingButton>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
}
