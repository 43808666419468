import {createSlice} from "@reduxjs/toolkit"

const initialState = {
  endpoints: {
    loading: false,
    data: [],
  },
  selectedEndpoint: null,
}

const endpointSlice = createSlice({
  name: 'endpoints',
  initialState,
  reducers: {
    fetch: (state, action) => {
      // fetch endpoints
    },
    setEndpoints: (state, action) => {
      const {payload} = action
      state.endpoints.data = payload
    },
    selectEndpoint(state, action) {
      const {payload} = action
      localStorage.setItem('endpoint', payload.id)
      state.selectedEndpoint = payload
    },
    setLoading(state, action) {
      const {payload} = action
      state[payload.type].loading = payload.loading
    },
  },
})

const endpointReducer = endpointSlice.reducer

export {endpointSlice, endpointReducer}
